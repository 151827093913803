import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, Document } from '@contentful/rich-text-types';

interface RichTextRendererProps {
  richTextDocument: Document;
}

export const RichTextRenderer: React.FC<RichTextRendererProps> = ({ richTextDocument }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node: any, children: React.ReactNode) => <h1>{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => <p>{children}</p>,
    },
  };

  return <div>{documentToReactComponents(richTextDocument, options)}</div>;
};

export default RichTextRenderer;
